import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Create flexible sizes for an image that stretches to fill its container.
// E.g. for a container whose max width is 800px, the automatic sizes would be:
// 200px, 400px, 800px, 1200px and 1600px –
// enough to provide close to the optimal image size for every device size / screen resolution.
// If you want more control over which sizes are output you can use the srcSetBreakpoints parameter.

const Image = ({ imgName }) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `)

  const image = data.allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === imgName
  )

  return image ? <Img fluid={image.node.fluid} /> : null
}

export default Image
