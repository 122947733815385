import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Header from '../components/header'
import Folio from '../components/folio'
import Services from '../components/services'
import Contact from '../components/contact'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Header />
      <Contact />
      <Services />
      <Folio />
      <Footer />
    </Layout>
  )
}

export default IndexPage
