import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from '../image'

import * as styles from './styles'
import StrikeHeading from '../stike-heading'
import { Section } from '../styled'

const Folio = () => {
  const data = useStaticQuery(graphql`
    query {
      allImagesJson {
        nodes {
          url
          id
        }
      }
    }
  `)

  console.log(data)

  return (
    <Section>
      <StrikeHeading heading={'Work'} />
      <div css={styles.folio}>
        {data.allImagesJson.nodes.map(({ url, id }) => (
          <a
            key={id}
            href={`https://www.instagram.com/p/${id}/`}
            target="_blank"
            css={styles.image}
            rel="noopener noreferrer"
          >
            <Image imgName={url} />
          </a>
        ))}
      </div>
    </Section>
  )
}

export default Folio
