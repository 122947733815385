import React from 'react'
import { css } from '@emotion/core'
import { COLOURS } from '../../constants'

const styles = {
  wrapper: css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 8px;
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      margin-top: 1px;
      background-color: ${COLOURS.boulder};
      position: absolute;
      z-index: -1;
    }
  `,
  heading: css`
    margin-bottom: 0;
    background-color: ${COLOURS.white};
    padding: 0 10px;
  `
}

const StrikeHeading = ({ heading }) => (
  <div css={styles.wrapper}>
    <h3 css={styles.heading}>{ heading }</h3>
  </div>
)

export default StrikeHeading