import { css } from '@emotion/core'
import { MEDIA_QUERY, COLOURS } from '../../constants'

export const folio = css`
  display: grid;
  grid-gap: 8px;
  width: 100%;
  padding: 10px 0 30px;
  grid-template-columns: repeat(2, 1fr);
  ${MEDIA_QUERY.aboveSm} {
    grid-template-columns: repeat(3, 1fr);
  }
`
export const image = css`
  border: 1px solid ${COLOURS.midGrey};
`
