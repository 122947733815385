import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import StrikeHeading from '../stike-heading'
import { Section } from '../styled'

const styles = {
  contact: css`
    font-size: 14px;
    line-height: 20px;
    font-family: relativefaux;
  `,
}

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          phone
        }
      }
    }
  `)

  return (
    <Section>
      <StrikeHeading heading={'Contact'} />
      <p css={styles.contact}>
        <a
          className={'textLink'}
          href={`mailto:${data.site.siteMetadata.email}`}
        >
          {data.site.siteMetadata.email}
        </a>
      </p>
      <p css={styles.contact}>{data.site.siteMetadata.phone}</p>
    </Section>
  )
}

export default Contact
